export default {
    "projectDetails": {
        "projectName": "Product Gap Brief"
    },
    "agGrid": {
        "licenceKey": "Using_this_{AG_Grid}_Enterprise_key_{AG-070089}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Levi_Strauss_&_Co.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{5_May_2026}____[v3]_[01]_MTc3NzkzNTYwMDAwMA==0ebb4c943a349f5f3f2f9a2f8e18dc71"
    },
    "datafields": {
        "finishDevelopmentTrack": ["5D", "Traditional", "FLX"],
        "gender": ["Men", "Women", "Kids", "All"],
        "region-countries": {
            "LSA": [
                "BR", "CA", "MX", "US", "AR", "BO", "CL", "CO",
                "CR", "PA", "PE", "UY", "VE"
            ],
            "US/Canada": ["CA", "US"],
            "LATAM": [
                "AR", "BO", "BR", "CL", "CO", "CR", "MX", "PA", 
                "PE", "UY", "VE"
            ],
            "LSE": ["EU", "TR"],
            "Nth Europe": ["EU", "MN"],
            "Sth Europe": ["EU", "TR"],
            "AMA": [
                "AU",
                "CN",
                "HK",
                "IN",
                "ID",
                "JP",
                "MY",
                "PK",
                "PH",
                "SG",
                "ZA",
                "KR",
                "TW",
                "TH",
                "VN",
                "MN"
            ],
            "Sth Asia MEA": ["IN", "PK", "ZA"],
            "Greater China": ["CN", "HK", "TW"],
            "East Asia-PAC": ["AU", "ID", "JP", "KR", "MY", "PH", "SG", "TH", "VN"],
            "Global": [
                "BR",
                "CA",
                "MX",
                "US",
                "AR",
                "BO",
                "CL",
                "CO",
                "CR",
                "PA",
                "PE",
                "UY",
                "VE",
                "EU",
                "TR",
                "AU",
                "CN",
                "HK",
                "IN",
                "ID",
                "JP",
                "MY",
                "PK",
                "PH",
                "SG",
                "ZA",
                "KR",
                "TW",
                "TH",
                "VN",
                "MN"
            ]
        },
        
        /** Actually Clusters */
        "cluster": [
            "US/Canada",
            "LATAM",
            "Nth Europe",
            "Sth Europe",
            "Sth Asia MEA",
            "Greater China",
            "East Asia-PAC",
            "Global"
        ],
        "clusterRegionMap": {
            "Global": "Global",
            "LSA": "LSA",
            "US/Canada": "LSA",
            "LATAM": "LSA",
            "LSE": "LSE",
            "Nth Europe": "LSE",
            "Sth Europe": "LSE",
            "AMA": "AMA",
            "Sth Asia MEA": "AMA",
            "Greater China": "AMA",
            "East Asia-PAC": "AMA"
        },
        "regionClusterMap": {
            "Global": [],
            "LSA": ["US/Canada", "LATAM"],
            "LSE": ["Nth Europe", "Sth Europe"],
            "AMA": ["Sth Asia MEA", "Greater China", "East Asia-PAC"]
        },
        
        
        "country": [
            "Belgium",
            "France",
            "Germany",
            "Italy",
            "Netherlands",
            "Poland",
            "Russia",
            "Spain",
            "Turkey",
            "United Kingdom",
            "Brazil",
            "Canada",
            "Mexico",
            "USA",
            "Australia",
            "Japan",
            "China",
            "India",
            "Hong Kong",
            "Indonesia",
            "Malaysia",
            "Palistan",
            "Philippines",
            "Singapore",
            "South Africa",
            "South Korea",
            "Taiwan",
            "Thailand",
            "Vietnam"
        ],
        "brand": [
            "Levis",
            "Dockers",
            "Denizen",
            "Signature"
        ],
        "typeOfRequest": [
            "New",
            "Carryover",
            "Change",
            "Early Delivery"
        ],
        "gapType": [
            "Soft Gap",
            "Hard Gap",
            "Non Gap"
        ],
        "requestReasonCodes": [
            "General SRT request",
            "Account SMU",
            "Extend Size Product",
            "Clubs",
            "Program Extension"
        ],
        "category": [
            "Tops",
            "Bottoms",
            "Accessories"
        ],
        "fabric": [
            "Denim",
            "Non-Denim",
            "Woven",
            "Knit",
            "Other"
        ],
        "priceTier": [
            "Tier 1",
            "Tier 2",
            "Tier 3"
        ],
        "channels": [
            "Wholesale",
            "Retail",
            "eCom exclusive",
            "Outlet",
            "WS & Retail",
            "WS & Outlet",
            "All"
        ],
        "pullForward": [
            "Yes",
            "No"
        ],
        "exclusivity": [
            "Yes",
            "No"
        ],
        "default_gtm_stage": null,
        "productLine": [
            "Authorized Vintage",
            "Collaboration",
            "Engineered Knit",
            "Licensed",
            "Line 8",
            "Made and Crafted",
            "Mainline",
            "Red",
            "Red Loop India",
            "Skateboarding",
            "Sports Club",
            "Vintage Clothing",
            "Wellthread",
            "Workwear",
        ]
    },
    "roleOrder": [ "RM", "RL", "RPL", "GM", "GML", "PD", "SP"],
    "roles": {
        "RM": "Regional Merch",
        "RL": "Regional Lead",
        "RPL": "Regional Planning Lead",
        "GM": "Global Merch",
        "GML": "Global Merch Lead",
        "PD": "Product Development",
        "SP": "Supply Planning",
        "SUM": "Summary",
        "SUPER": "SuperUser",
        "VO": "View Only",
    },
    "states": {
        "Regional Merch": 0,
        "Regional Lead": 0,
        "Regional Planning Lead": 1,
        "Global Merch": 2,
        "Global Merch Lead": 2,
        "Product Development": 4,
        "Supply Planning": 5
    },
    "roleToRoute": {
        "Regional Merch": "list",
        "Regional Lead": "regionalLead",
        "Regional Planning Lead": "rmapproval",
        "Global Merch": "gmreview",
        "Global Merch Lead": "gmapproval",
        "Product Development": "pdapproval",
        "Supply Planning": "spapproval",
        "Summary": "summary"
    },
    "pc9Validation": [
        "^\\w{5}-\\d{4}$",
        "^\\d{3}$",
        "^\\w{5}$"
    ],
    "actualPC9": [
        "^\\w{5}-\\d{4}$"
    ],
    "targetCOGS": [
        "^[1-9]\\d*(\\.\\d+)?$"
    ],
    "volumeMinMax": [
        1500,
        1000000
    ],
    "protoNeeds": [0,
        4
    ],
    "sampleNeeds": [0,
        15
    ],
    "TICK_SYMBOL": "&#10004",
    "CROSS_SYMBOL": "&#10005",
    "fabricCode": [
        "^\\FA\\d{4}$",
        "^\\FA\\d{5}$",
        "^\\FA\\d{6}$"
    ],
    "leadTime": [
        7,
        150
    ],
    "lookupTypes": {
        "gtmStages": "GTMStage",
        "schedules": "Schedule"
    },
    // RIO-32
    "subclassesExcludedFromSrt": {
        "Bags": 1,
        "Belts": 1,
        "Bodywear": 1,
        "Gloves": 1,
        "Headgear": 1,
        "Scarves/Neckwear": 1,
        "Socks": 1,
        "Trinkets": 1,
        "Wallets": 1,
        "Blazers": 1,
        "Jackets": 1,
        "Lined Truckers": 1,
        "Sherpa Lined Truckers": 1,
        "Sweaters": 1,
        "Unlined Truckers": 1,
        "Vests": 1,
        "Woven Blouses": 1,
        "Woven Shirts": 1,
    },
}
